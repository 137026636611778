<template>
    <!-- 현재 사용되지 않는 모달 -->
    <div class="modal-photos-selector">
        <div class="head">
            <i @click="$emit('close')" class="material-icons">close</i>
            <div class="title" v-html="$translate('SEND_PHOTO')" />
        </div>
        <UserPhotosSelector class="ver-scroll" :user="options.user" @onPhotos="photos => (images = photos)" />
        <footer class="footer">
            <button
                class="btn btn-primary btn-block flex-wrap"
                :class="disabled"
                @click="onClickSendPicture"
                v-html="$translate('SEND')"
            />
        </footer>
    </div>
</template>
<script>
export default {
    name: 'ModalPhotosSelector',
    props: ['options'],
    data: () => ({
        images: null,
    }),
    mounted() {
        this.$bus.$on('onClickSendPicture', this.close)
    },
    beforeDestroy() {
        this.$bus.$off('onClickSendPicture', this.close)
    },
    computed: {
        disabled() {
            return (this.images || []).filter(img => img.checked).length === 0 ? 'disabled' : ''
        },
        chat() {
            return this.$store.getters.chat
        },
        myPremium() {
            if (this.chat.user.$$premium) {
                const premium = this.$options.filters.hasProduct(this.chat.user, 'premium')
                return this.$store.getters.agent.id === premium.agent_id
            }
            return false
        },
        isAbleRecommend() {
            if (!this.$store.getters.agent) return true
            if (!this.chat.user.$$premium) return true
            return this.$store.getters.agent.is_premium && this.chat.user.$$premium && this.myPremium
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        onClickSendPicture() {
            if (!this.isAbleRecommend) {
                this.isAbleRecommendAlert()
                return
            }

            const photoUrls = (this.images || []).filter(img => img.checked).map(img => img.photo_url) || []

            if (photoUrls.length === 0) {
                this.$toast.error('SELECT_PICTURES')
                return
            }

            this.$bus.$emit('onClickSendPicture', {
                selectedImageUrls: photoUrls,
                source: this.options.user,
            })
        },
        isAbleRecommendAlert() {
            this.$modal.basic({
                title: 'NOTICE',
                body: this.$translate('CANNOT_RECOMMEND_PREMIUM_USER'),
                buttons: [
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
        },
    },
}
</script>

<style lang="scss" scoped>
$footer-height: 80px;

.modal-photos-selector {
    padding: 0;

    .head {
        padding: 16px 16px 20px;

        .material-icons {
            color: #7f7e83;
            margin-bottom: 16px;
        }
    }

    .user-photos-selector {
        width: 100vw;
        padding: 0 16px calc(#{$footer-height} + 18px);

        /deep/ .grid-col {
            grid-gap: 12px;
            $sending-picture-size: calc((100vw - 16px * 2 - 12px) / 2);

            .img-profile {
                width: $sending-picture-size;
                height: $sending-picture-size;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: $footer-height;
        padding: 16px 20px;
        border-top: solid 1px #f1f2f4;
        background-color: white;

        .btn {
            height: 48px;
        }
    }
}
</style>
